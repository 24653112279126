// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const RPC_URL = getRpcUrl();
export const DISABLE_SAVES = false;

//RDK Checks how we're accessing this page, and returns the appropriate RPC page
function getRpcUrl() {
  var returnVal = "";
  if (window.location.href.indexOf('dmm.onpowerusa.com') != -1) {
    //RDK The mobile app is homed at dmm.onpowerusa.com but works with Duke
    //RDK We'll need a new home for duke to make it work in both places
    //returnVal = "https://www.onpowerusa.com/fleet/";
    returnVal = "https://duke.onpowerusa.com/";
  } else if (window.location.href.indexOf('mm.i2solutionsllc.com') != -1) {
    returnVal = "https://www.onpowerusa.com/i2/";
  } else if (window.location.href.indexOf('http://rdktest.onpowerrick-pc/') != -1) {
    returnVal = "http://192.168.10.108/fleet/";
  }

  return returnVal;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
